(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-gallery-slider]");

        $.lib_flickity(function(){
            if (slider.length) {
                slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    autoPlay: 6000,
                    prevNextButtons: true,
                    pauseAutoPlayOnHover: true
                });
            }
        });
        $.importScript(cdnjs.lightgallery, function(){
            slider.lightGallery({
                thumbnail: true,
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: true,
                download: true,
                autoplay: false,
                selector: ".elm_item_image",
                autoplayControls: true
            });
        });

        window.lui[name] = comp;
    }
})("comp_site_detail");