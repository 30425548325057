(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let table = selector.find(".part_table");
        let image = selector.find(".elm_body_image");

        if (image.length) {
            table.on("mouseenter", "[data-image]", function(){
                let elm = $(this);
                let data = elm.data("image");

                image.find("img").attr("src",data[0]);
                image.find(".part_ui_title").text(data[1]);
            });
        }

        table.on("click", "[data-url]", function(){
            let elm = $(this);

            window.location.href = elm.data("url");
        });

        window.lui[name] = comp;
    }
})("comp_base_pricelist");