(function($) {
    fn_nl_lib_anchor();

    $.libInit(".part_ui_btn", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-recaptcha]", "lib_reCaptcha", function(selector) {
        $(selector).lib_reCaptcha("recaptcha");
    });


    let picture = document.createElement('picture'),
        strObj = '' + picture;

    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }

    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        bodyLoaded(function(){
            $.importScript(cdnjs.cookieconsent);
        });
    }
    
    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    sr.sync();
                });
            }
        });
    });
    
    $('.part_form_contact').antiSpam();
})(jQuery);