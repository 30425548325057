(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let gallery = selector.find("[data-gallery]");

        if (gallery.length) {
            $.importScript(cdnjs.lightgallery, function(){
                gallery.lightGallery({
                    thumbnail: true,
                    exThumbImage: 'data-exthumbimage',
                    fullScreen: false,
                    zoom: true,
                    actualSize: false,
                    hash: true,
                    download: true,
                    autoplay: false,
                    autoplayControls: true
                });
                doc.on("click","[data-gallery-open]",function(){
                    $(this).closest(".part_item_gallery").find("[data-gallery]").children("a:first-of-type").trigger("click");
                });
            });
        }

        window.lui[name] = comp;
    }
})("comp_base_gallery");