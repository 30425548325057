(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_map(map) {
            if (map.length) {
                let css_wait = setInterval(function(){
                    if (selector.find(`.col--map[data-reveal="revealed"]`).length) {
                        clearInterval(css_wait);
                        $.importScript(cdnjs.googlemap, function () {
                            map.nl_google_map('init');
                            map.nl_google_map('markers_add');

                            selector.on("click", "[data-marker]",function (e) {
                                let elm = $(this);

                                map.nl_google_map("marker_click",elm.data("marker"));
                            })
                        });
                    }
                },0);

                fns.fn_map = fn_map;
            }
        })(selector.find("[data-map]"));

        (function fn_slider(carousel) {
            if (carousel.length) {
                $.lib_flickity(function(){
                    carousel.flickity({
                        groupCells: '25%',
                        cellAlign: 'left',
                        contain: true,
                        wrapAround: true,
                        pageDots: false,
                        draggable: true,
                        autoPlay: false,
                        pauseAutoPlayOnHover: true
                    });
                });
            }
        })(selector.find("[data-carousel]"));

        window.lui[name] = comp;
    }
})("comp_base_location");