(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-slider]");
        let image = selector.find(".elm_body_image");

        $.lib_flickity(function(){
            if (slider.length) {
                slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    autoPlay: 6000,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: true,
                    on: {
                        change: function(index) {
                            image.children(".col").removeClass("state--active").eq(index).addClass("state--active");
                        }
                    }
                });
            }
        });

        window.lui[name] = comp;
    }
})("comp_base_visual");